// BOOTSTRAP + FLEX IMPORTS
import { LinkContainer } from 'react-router-bootstrap'
import { Breadcrumb, Row,Container} from 'react-bootstrap'
import Flex from '@react-css/flex'
// INTERFACES
import { BannerProps, BCBannerProps } from '../../interfaces/utils'
import { useDispatch } from 'react-redux'
import { changeSubCategory } from '../../state/reducers/ConhecerSlice'

export function BCBanner({category,subCategory}: BCBannerProps) : JSX.Element {
    const dispatch = useDispatch();

    return (
        <Flex flexDirection='row' id="banner">
            <Container fluid>
                <Row className='filter-drop' id="img">
                    <h1 > 
                        <Breadcrumb>
                            <LinkContainer to={'/' + category.toLowerCase()} state={{category:category}}>    
                                <Breadcrumb.Item> {category} </Breadcrumb.Item>
                            </LinkContainer>
                        
                            <LinkContainer to={'/' + category.toLowerCase()} state={{category:subCategory}}
                                onClick={() => dispatch(changeSubCategory(subCategory))}>
                                <Breadcrumb.Item> {subCategory} </Breadcrumb.Item>
                            </LinkContainer>
                        </Breadcrumb>
                    </h1>
                </Row>
            </Container>
        </Flex>
    )
}

export function Banner({cssID,title}:BannerProps) : JSX.Element {
    return (
        <Flex flexDirection='row' id={cssID}>
            <Container fluid>
                <Row className='filter-drop' id="img">
                    <h1> {title} </h1>
                </Row>
            </Container>
       </Flex>
    )
}
