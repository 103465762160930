import { Col} from 'react-bootstrap'
import Flex from '@react-css/flex'
import RelatedCard from '../RelatedCard/RelatedCard'
import { ArticleBodyProps } from '../../interfaces/utils'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext'

function ArticleBody({articles,type}:ArticleBodyProps) : JSX.Element {

    let art = articles?.at(0)?.attributes.Entidades ?? undefined;
    const [language] = useContext(LanguageContext); 
    let related = language === "PT" ? "Artigos Relacionados" : "Related Articles";
    
    return ( 
        <Flex flexDirection='column'>
            <Col className="col-11" id="article-text">
                <h4 id="entity-title"> 
                    {articles?.at(0)?.attributes.Titulo}
                </h4>
                {articles?.at(0)?.attributes.Paragrafos.map((p) => 
                    <ReactMarkdown children={p.Paragrafo} key={p.id} className="entity-par" />
                )}
                {type === "Corvo" && art?.data && art.data.length > 0  ? 
                <>
                    <h5 id="articles-subtitle"> {related} </h5>
                    <RelatedCard articles={articles?.at(0)?.attributes.Entidades} />
                </>
                :
                null }
            </Col>
        </Flex>
    )
}

export default ArticleBody