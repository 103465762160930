import { Card } from "react-bootstrap"
import { IconContext } from "react-icons"
import { TbFeather } from "react-icons/tb"
import { EntityCardProps } from "../../interfaces/utils"

function EntityCard({card}:EntityCardProps) {
  return (
    <Card>
        <Card.Img variant="top" src={card.attributes.Thumbnail.data.attributes.formats.small.url} style={{aspectRatio: "16 / 9"}} loading="lazy" alt={card.attributes.Titulo}/>
        <Card.ImgOverlay>
            <IconContext.Provider value={{size:'17%'}} > 
                <TbFeather className="box-icon-patrimonio-ov"/>
            </IconContext.Provider>
        </Card.ImgOverlay>
        <Card.Body className="card-background">
            <Card.Text> {card.attributes.Titulo} </Card.Text>
        </Card.Body>
    </Card>
  )
}

export default EntityCard