export const ContactsPoints = 
[
    {
        "name": "Câmara Municipal do Corvo",
        "coordinates": [39.672976, -31.113443]
    },
    {
        "name": "Unidade de Saúde da Ilha do Corvo",
        "coordinates": [39.67267569938509, -31.112564457468096]
    },
    {
        "name": "Associação dos Bombeiros Voluntários",
        "coordinates": [39.670567243310856, -31.11305053786851]
    },
    {
        "name": "Posto de Medicamentos da Santa Casa da Misericórdia",
        "coordinates": [39.672952405109605, -31.112903701080885]
    },
    {
        "name": "Casa do Bote",
        "coordinates": [39.670292, -31.113604]
    },
    {
        "name": "Aeródromo do Corvo",
        "coordinates": [39.67062180675605, -31.11343535151884]
    },
    {
        "name": "Biblioteca Municipal do Corvo",
        "coordinates": [39.673769, -31.112328]
    },
    {
        "name": "Centro de Interpretação Ambiental do Corvo",
        "coordinates": [39.673636, -31.111896]
    },
    {
        "name": "Casa do Tempo - Ecomuseu do Corvo",
        "coordinates": [39.673732064619074, -31.111591277156272]
    },
    {
        "name": "Centro de Reabilitação de Aves Selvagens do Corvo",
        "coordinates": [39.673636, -31.111896]
    },
    {
        "name": "EBS Mouzinho da Silveira",
        "coordinates": [39.67280430922505, -31.11321762800513]
    },
    {
        "name": "Creche - Santa Casa da Misericórdia do Corvo",
        "coordinates": [39.67269128846863, -31.11351130158038]
    },
    {
        "name": "Guarda Nacional Republicana",
        "coordinates": [39.67199552074794, -31.114470726377746]
    },
    {
        "name": "Polícia Marítima",
        "coordinates": [39.67138958780711, -31.111698626755878]
    }

]