import {BrowserRouter as Router,Route,Routes} from 'react-router-dom/'
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Corvo from "./pages/Corvo/Corvo";
import Conhecer from "./pages/Conhecer/Conhecer";
import Birdwatching from "./pages/Birdwatching/Birdwatching";
import Pesquisa from './pages/Pesquisa/Pesquisa';
import Entidade from './pages/Entidade/Entidade';
import Contactos from './pages/Contactos/Contactos';
import LanguageContextProvider from './context/LanguageContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import globalStore from './state/store';
import MapaInterativo from './pages/MapaInterativo/MapaInterativo';

const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={globalStore}>
      <QueryClientProvider client={queryClient}>
        <LanguageContextProvider>
          <Router>
            <Header/>
            <Routes>
              <Route element = {<Home />} path="/"/>
              <Route element = {<Corvo />} path="/corvo"/>
              <Route element = {<Conhecer />} path="/conhecer" />
              <Route element = {<Entidade />} path="/entidade/:id"/>
              <Route element = {<Birdwatching />} path="/birdwatching"/>
              <Route element = {<Pesquisa />} path="/pesquisar"/>
              <Route element = {<Contactos />} path="/contactos"/>
              <Route element = {<MapaInterativo />} path="/mapa" />
            </Routes>
          </Router>
        </LanguageContextProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
