// BOOTSTRAP + FLEX IMPORTS
import { Col} from 'react-bootstrap'
import Flex from '@react-css/flex'
// REACT
import { useState,useEffect } from 'react'
import { BCBanner} from '../../components/Banners/Banners'
import { EmbededMap } from '../../components/EmbededMap/EmbededMap'
import { getEntity } from '../../utils/requests'
import { useQuery } from 'react-query'
import { ThumbnailJSON } from '../../interfaces/api_responses'
import { Paragrafo } from '../../interfaces/api_responses'
// IMAGE IMPORTS
import { useLocation } from 'react-router-dom'
import ImageModal from '../../components/ImageModal/ImageModal'
import SideCarousel from '../../components/SideCarousel/SideCarousel'
import RelatedCard from '../../components/RelatedCard/RelatedCard'
import IconContacts from '../../components/IconContacts/IconContacts'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

function Entidade() : JSX.Element {

  const [show, setShow] = useState(false);
  const [windowWidth,setWindowWidth] = useState(window.innerWidth);

  let ID = useLocation().pathname.split("/")[2].valueOf();
    
  const {data,isSuccess} = useQuery({queryKey:['entity',ID],queryFn: () => getEntity(ID)});

  let pars:Paragrafo[] | undefined = data?.attributes.Paragrafos;
  let imgs:ThumbnailJSON[] | undefined = data?.attributes.Imagens.data;
    
  const handleClose = () : void => setShow(false);
  const handleShow = () : void => setShow(true);
  
  useEffect(() => {
      const handleResize = () : void => setWindowWidth(window.innerWidth);
      setTimeout(handleResize,500);
      window.addEventListener('resize',handleResize);
      return () => window.removeEventListener('resize',handleResize);
  })

  return (
      <>
      { windowWidth >= 1280 ? <BCBanner category={"Conhecer"} subCategory={data?.attributes.Categoria ?? ""}/> : 
            <SideCarousel imgs={imgs} triggerDisplay={handleShow}/>  
      }
      <Flex flexDirection='row'>
        <Col className={windowWidth>=1280 ? 'col-1' :''}/>
          
          <Flex flexDirection='column'>
            <Col className="col-12">          
            
              <h2 id="entity-title"> {data?.attributes.Titulo} </h2>

              {pars?.map((p) => <ReactMarkdown children={p.Paragrafo} key={p.id} className="entity-par" /> )}

              { data?.attributes.Contactos ? <IconContacts telephone={data.attributes.Contactos.Telemovel} 
                email={data.attributes.Contactos.Email} location={data.attributes.Contactos.Localizacao}
                website={data.attributes.Contactos.Website}/> : null}

            </Col>

            {isSuccess ?
              <EmbededMap type="point" coordinates={data?.attributes.MapaIntegrado ? data?.attributes.MapaIntegrado.Coordenadas.coordinates : [39.7031, -31.1119]}/>
            : null }

            { data?.attributes.Relacionadas? <h4 id="articles-subtitle"> Artigos Relacionados </h4> : null }

            <RelatedCard articles={undefined} /> 
          
          </Flex>
        { windowWidth >= 1280 && ( <SideCarousel imgs={imgs} triggerDisplay={handleShow}/> )}       
      </Flex>

      <ImageModal imgs={imgs} show={show} handleClose={handleClose}/>
      </>
  )    
}

export default Entidade