import { Row,Col } from 'react-bootstrap'
import { TbArrowNarrowRight } from 'react-icons/tb'
import { IconContext } from 'react-icons'
import { LinkContainer } from 'react-router-bootstrap'
import { SearchResultsProps } from '../../interfaces/utils'
import { useQuery } from 'react-query'
import { getEntities } from '../../utils/requests'
import { EntityJSON } from '../../interfaces/api_responses'
import { useEffect,useContext } from 'react'
import {LanguageContext} from '../../context/LanguageContext'
import SearchPagination from '../SearchPagination/SearchPagination'
import { useSelector } from 'react-redux'
import { StoreState } from '../../state/store'

function SearchResults({headerTitle,query}:SearchResultsProps) :JSX.Element {

    const [language] = useContext(LanguageContext)
    
    const {Page} = useSelector((state:StoreState) => state.search);
    const {data,refetch} = useQuery({queryKey:['entities'],queryFn: () => getEntities(query,language,Page)});

    useEffect(() => {
        setTimeout(refetch,200);
    },[query,language,Page]);

    let pageTotal: number = (data?.meta.pagination.pageCount) ?? 1;
 
    return (
        <>
            {data?.data.map((ent:EntityJSON) =>
                <Row className="results">
                    <Col xs={5} md={3}>
                        <img src={ent.attributes.Thumbnail?.data.attributes.formats.small.url} alt="123" className="img-fluid"/>
                    </Col>

                    <Col xs={6} md={8} >
                        <h3> {ent.attributes.Titulo} </h3>
                        <p> {ent.attributes.Paragrafos[0].Paragrafo} </p>

                        <LinkContainer to={'/entidade/' + ent.id}>
                            <h5> {headerTitle} 
                                <IconContext.Provider value={{size:"3vh"}}>
                                    <TbArrowNarrowRight/>
                                </IconContext.Provider>
                            </h5>
                        </LinkContainer>
                    </Col>

                    <Col xs={1} md={1} className="col-2"/>
                </Row>
            )}
            <SearchPagination pageTotal={pageTotal} />
        </>
    )
}

export default SearchResults