import { ReactElement, useState } from 'react'
import { Pagination } from 'react-bootstrap';
import { PaginatedResults } from '../PaginatedResults/PaginatedResults';
import { useDispatch } from 'react-redux';
import { changeBWPage } from '../../state/reducers/BirdwatchingSlice';
import { changeConhecerPage } from '../../state/reducers/ConhecerSlice';
import { PaginationProps } from '../../interfaces/utils';

function CardsPagination({pageTotal,page}:PaginationProps) {

    const [selectedPage,setSelectedPage] = useState<number>(1);
    const dispatch = useDispatch();
    
    const updatePage = (pageNr:number) => {
        setSelectedPage(pageNr);
        if (page === "birdwatching") {
            dispatch(changeBWPage(pageNr))
        }
        else {
            dispatch(changeConhecerPage(pageNr));
        }
    }
    
    let pageItems: ReactElement[] = [];

    pageItems.push(
      <>
      <Pagination.First onClick={() => updatePage(1)} disabled={selectedPage == 1}/>
      <Pagination.Prev onClick={() => updatePage(selectedPage -1)} disabled={selectedPage == 1} />
      </>
  )

    for (let index=1;index<=pageTotal;index+=1) {
      pageItems.push(
        <Pagination.Item key={index} active={selectedPage === index} onClick={() => updatePage(index)}>
          {index}
        </Pagination.Item>
      );
    }
    
    pageItems.push(
      <>
      <Pagination.Next onClick={() => updatePage(selectedPage +1)} disabled={selectedPage == pageTotal} />
      <Pagination.Last onClick={() => updatePage(pageTotal)} disabled={selectedPage == pageTotal}/>
      </>
  )
  
  return ( <PaginatedResults pageItems={pageItems} /> )
}

export default CardsPagination