import { ReactElement, useState } from 'react'
import { Pagination } from 'react-bootstrap';
import { PaginatedResults } from '../PaginatedResults/PaginatedResults';
import { useDispatch } from 'react-redux';
import { changeSearchPage } from '../../state/reducers/SearchSlice';
import { PaginationProps } from '../../interfaces/utils';

function SearchPagination({pageTotal}:PaginationProps) {

    const [selectedPage,setSelectedPage] = useState<number>(1);
    const dispatch = useDispatch();

    const updatePage = (pageNr:number) => {
        setSelectedPage(pageNr);
        dispatch(changeSearchPage(pageNr));
    }

    let pageItems: ReactElement[] = [];
    let middle = pageTotal / 2;

    pageItems.push(
        <>
        <Pagination.First onClick={() => updatePage(1)} disabled={selectedPage == 1}/>
        <Pagination.Prev onClick={() => updatePage(selectedPage -1)} disabled={selectedPage == 1} />
        </>
    )

    for (let index=1;index<=middle;index+=1) {
      pageItems.push(
        <Pagination.Item key={index} active={selectedPage === index} onClick={() => updatePage(index)}
            hidden={selectedPage > middle}> {index} </Pagination.Item>
      );
    }

    pageItems.push( <Pagination.Ellipsis /> )

    for(let ind = middle + 1; ind <= pageTotal;ind++) {
        pageItems.push(
            <Pagination.Item key={ind} active={selectedPage === ind} onClick={() => updatePage(ind)} 
                hidden={selectedPage <= middle}> {ind} </Pagination.Item>
        )
    }

    pageItems.push(
        <>
        <Pagination.Next onClick={() => updatePage(selectedPage +1)} disabled={selectedPage == pageTotal} />
        <Pagination.Last onClick={() => updatePage(pageTotal)} disabled={selectedPage == pageTotal}/>
        </>
    )

  return ( <PaginatedResults pageItems={pageItems}/> )
}

export default SearchPagination