import { createSlice } from "@reduxjs/toolkit";

export const BirdwatchingSlice = createSlice({
    name: "birdwatching",
    initialState: {
        BWCategory: "Enquadramento",
        Page: 1
    },
    reducers: {
        changeBWCategory: (state,action) => {
            state.BWCategory = action.payload;
        },
        changeBWPage: (state,action) => {
            state.Page = action.payload;
        }

    }
})

export const {changeBWCategory,changeBWPage} = BirdwatchingSlice.actions;
export default BirdwatchingSlice.reducer;