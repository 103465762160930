import { LinkContainer } from 'react-router-bootstrap'
import { Card,Col,Row,Container } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { TbBuildingCastle} from 'react-icons/tb'
import { EntityJSON } from '../../interfaces/api_responses'
import { RelatedCardProps } from '../../interfaces/utils'

function RelatedCard({articles}:RelatedCardProps) : JSX.Element {
    return (
        <Container fluid className='card-container'>
            <Row xs={1} sm={2} md={2} lg={3} xl={2} className="g-4"> 
                {articles?.data.map((entity:EntityJSON) => {
                    return (
                        <Col>
                            <LinkContainer to={'/entidade/' + entity.id}>                    
                                <Card > 
                                    <Card.Img variant="top" src={entity.attributes.Thumbnail?.data.attributes.formats.small.url} style={{aspectRatio: "16 / 9"}}/>
                                    <Card.ImgOverlay>
                                    <IconContext.Provider value={{size:'17%'}} > 
                                        <TbBuildingCastle className="box-icon-patrimonio-ov"/>
                                    </IconContext.Provider>
                                    </Card.ImgOverlay>
                                    <Card.Body className="card-background">
                                        <Card.Text> {entity.attributes.Titulo} </Card.Text>          
                                    </Card.Body>
                                </Card>
                            </LinkContainer>
                        </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
}

export default RelatedCard