// REACT
import { useState,useEffect } from 'react'
// LANGUAGE
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

function Contactos() : JSX.Element {

  const [windowWidth,setWindowWidth] = useState(window.innerWidth);
    
  useEffect(() => {
    const handleResize = () : void => setWindowWidth(window.innerWidth); 
    setTimeout(handleResize,500);
    window.addEventListener('resize',handleResize);
    return () => window.removeEventListener('resize',handleResize);
  })

  return (
      <> { windowWidth >= 1280 ? <Desktop /> : <Mobile /> } </>
  )    
}

export default Contactos