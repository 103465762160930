// LEAFLET IMPORTS
import { MapContainer, TileLayer, Marker,Popup} from 'react-leaflet'
import { EmbededMapProps, leafletIcon } from '../../interfaces/utils'
import { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext'
import ContactsMarkers from '../ContactsMarkers/ContactsMarkers'

export function EmbededMap({coordinates,page}:EmbededMapProps) : JSX.Element {

    const [language] = useContext(LanguageContext);
    const mapsText = language === "PT" ? "Abrir no Google Maps" : "Open in Google Maps";
    const zoomLevel = coordinates[0] === 39.7031 && coordinates[1] === -31.1119 ? 12 : 20;

    return (
        <MapContainer center={coordinates} zoom={zoomLevel} scrollWheelZoom={true} className="leaflet-container">
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>

          {page === "Contactos" ? <ContactsMarkers /> 
          : 
            <Marker position={coordinates} icon={leafletIcon}>   
              <Popup>
                <p> LAT: {coordinates[0]} </p>
                <p> LON: {coordinates[1]} </p>
                <a href={`https://www.google.com/maps/place/${coordinates[0]},${coordinates[1]}`} target="_blank"> {mapsText} </a>
              </Popup>
            </Marker>
          }

      </MapContainer>
    )
}
