import { createSlice } from "@reduxjs/toolkit";

export const MenuSlice = createSlice({
    name:"menu",
    initialState: {
        highlightPage: ""
    },
    reducers: {
        setHighlightPage: (state,action) => {
            state.highlightPage = action.payload
        }
    }

})

export const {setHighlightPage} = MenuSlice.actions;
export default MenuSlice.reducer;
