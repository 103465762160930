import { LinkContainer } from 'react-router-bootstrap'
import { Card } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { TbBuildingCastle,TbBrush,TbLeaf,TbGlassFull,TbBed } from 'react-icons/tb'
import { EntityCardProps } from '../../interfaces/utils'

function LinkedEntityCard({card}:EntityCardProps) {
  return (
    <LinkContainer to={'/entidade/'+ card.id}>                    
        <Card>
            <Card.Img variant="top" src={card.attributes.Thumbnail.data.attributes.formats.small.url} style={{aspectRatio: "16 / 9"}} loading="lazy" alt={card.attributes.Titulo}/>
            <Card.ImgOverlay>
                <IconContext.Provider value={{size:'17%'}} > 
                    {card.attributes.Categoria === "Patrimonio" ? <TbBuildingCastle className="box-icon-patrimonio-ov"/> : null}
                    {card.attributes.Categoria === "Artesanato Local" ? <TbBrush className="box-icon-gastronomia-ov"/> : null}
                    {card.attributes.Categoria === "Natureza" ? <TbLeaf className="box-icon-natureza-ov"/> : null}
                    {card.attributes.Categoria === "Restaurantes" ? <TbGlassFull className="box-icon-comer-ov"/> : null}
                    {card.attributes.Categoria === "Alojamento" ? <TbBed className="box-icon-dormir-ov"/> : null}
                </IconContext.Provider>
            </Card.ImgOverlay>
            <Card.Body className="card-background">
                <Card.Text> {card.attributes.Titulo} </Card.Text>
            </Card.Body>
        </Card>
    </LinkContainer>
  )
}

export default LinkedEntityCard