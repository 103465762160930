// REACT-BOOTSTRAP + REACT-ICONS IMPORTS
import { TbBuildingCastle,TbLeaf,TbBed, TbBrush, TbGlassFull} from 'react-icons/tb'
// REACT
import { useContext } from 'react'
// OTHER IMPORTS
import Flex from '@react-css/flex'
import { KnowCard } from '../../components/KnowCard/KnowCard'
import { Banner } from '../../components/Banners/Banners'
// LANGUAGE
import { LanguageData } from '../../interfaces/language'
import { LanguageContext } from '../../context/LanguageContext'
import VerticalRow from '../../components/VerticalRow/VerticalRow'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../state/store'
import { changeSubCategory } from '../../state/reducers/ConhecerSlice'

const languageData: LanguageData = require('../../utils/languageData.json');

export function Desktop() : JSX.Element {

        const [language] = useContext(LanguageContext); 
        const conhecer = languageData.language[language].page.conhecer;
        const {subCategory} = useSelector((state:StoreState) => state.conhecer);
        const dispatch = useDispatch();
         
        return (
            <>
            <Banner title={conhecer.banner} cssID="categories-banner"/>
            <Flex flexDirection='row'>
                <Flex flexDirection='column' id="flex-style">

                    <VerticalRow iconState={subCategory === "Patrimonio"} iconNode={<TbBuildingCastle
                            className={subCategory === "Patrimonio" ? "box-explore box-inactive box-icon-patrimonio-in" : "box-explore box-icon-patrimonio"} />}
                            colClass={subCategory === "Patrimonio" ? "box-style box-patrimonio box-unpad" : "box-style box-patrimonio"}
                            colTitle={conhecer.categories[0].title}
                            stateUpdate={() => dispatch(changeSubCategory("Patrimonio"))} />

                    <VerticalRow iconState={subCategory === "Artesanato Local"} iconNode={<TbBrush 
                            className={subCategory === "Artesanato Local" ? "box-explore box-inactive box-icon-gastronomia-in" : "box-explore box-icon-gastronomia"}/>}
                            colClass={subCategory === "Artesanato Local" ? "box-style box-gastronomia box-unpad" : "box-style box-gastronomia"}
                            colTitle={conhecer.categories[1].title}
                            stateUpdate={() => dispatch(changeSubCategory("Artesanato Local"))} />

                    <VerticalRow iconState={subCategory === "Natureza"} iconNode={<TbLeaf
                            className={subCategory === "Natureza" ? "box-explore box-inactive box-icon-natureza-in" : "box-explore box-icon-natureza"}/>}
                            colClass={subCategory === "Natureza" ? "box-style box-natureza box-unpad" : "box-style box-natureza"}
                            colTitle={conhecer.categories[2].title}
                            stateUpdate={() => dispatch(changeSubCategory("Natureza"))} />

                    <VerticalRow iconState={subCategory === "Restaurantes"} iconNode={<TbGlassFull
                            className={subCategory === "Restaurantes" ? "box-explore box-inactive box-icon-comer-in" : "box-explore box-icon-comer"}/>}
                            colClass={subCategory === "Restaurantes" ? "box-style box-comer box-unpad": "box-style box-comer"}
                            colTitle={conhecer.categories[3].title}
                            stateUpdate={() => dispatch(changeSubCategory("Restaurantes"))} />                    

                    <VerticalRow iconState={subCategory === "Alojamento"} iconNode={<TbBed 
                            className={subCategory === "Alojamento" ? "box-explore box-inactive box-icon-dormir-in": "box-explore box-icon-dormir"}/>}
                            colClass={subCategory === "Alojamento" ? "box-style box-dormir box-unpad" : "box-style box-dormir"}
                            colTitle={conhecer.categories[4].title}
                            stateUpdate={() => dispatch(changeSubCategory("Alojamento"))} />  
                
                </Flex>
                <KnowCard />           
            </Flex>

            </>
        )
}