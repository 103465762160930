import { Row,Col,Form,FormGroup } from 'react-bootstrap'
import { TbSearch } from 'react-icons/tb'
import { IconContext } from 'react-icons'
import { Banner } from '../../components/Banners/Banners'
import { ChangeEvent, useContext,useState } from 'react'
import { LanguageContext } from '../../context/LanguageContext'
import SearchResults from '../../components/SearchResults/SearchResults'

function Pesquisa() : JSX.Element {

  const [language] = useContext(LanguageContext); 
  const [searchQuery,setSearchQuery] = useState<string>("");
  let bannerText = language === "PT" ? "Pesquisar" : "Search";
  let placeholder = language === "PT" ? "Introduza o seu texto de pesquisa" : "Insert your search text";

  return (
      <>
        <Banner title={bannerText} cssID="categories-banner"/>
          <Row>
            <Col xs={1} lg={2}/>
            <Col xs={10} lg={8}>
              <Form onSubmit={(e:ChangeEvent<HTMLFormElement>) => {
                e.preventDefault();
              }}>
                <FormGroup controlId='searchForm'>
                  <Form.Control type="text" placeholder={placeholder} onChange={(e:ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}/>
                    <IconContext.Provider value={{}}>
                      <TbSearch id="results-search"/>
                    </IconContext.Provider>
                </FormGroup>
              </Form>
              <br/>
              <SearchResults headerTitle={language ==="PT" ? "Ver Mais" : "See More"} query={searchQuery}/>
            </Col>
          </Row>
      </>
  )
}

export default Pesquisa