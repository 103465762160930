import { IconContext } from 'react-icons'
import { TbFilter } from 'react-icons/tb'
import { CategoriesButtonProps } from '../../interfaces/utils'

function CategoriesButton({open,stateUpdate}:CategoriesButtonProps) : JSX.Element {
    return (
        <div id="categories-div" onClick={stateUpdate}>
            <IconContext.Provider value={{"size":"3.5vh"}}>
                <TbFilter aria-controls="example-collapse-text" aria-expanded={open} id="categories-button"/>
            </IconContext.Provider>
        </div>
    )
}

export default CategoriesButton