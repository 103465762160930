import  { useState,useEffect } from 'react'
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

function Birdwatching() : JSX.Element {

    const [windowWidth,setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () : void => setWindowWidth(window.innerWidth); 
        setTimeout(handleResize,500);
        window.addEventListener('resize',handleResize);
        return () => window.removeEventListener('resize',handleResize);
    })

  return <> { windowWidth >= 1280 ? <Desktop /> : <Mobile />} </>;
}

export default Birdwatching