// REACT-BOOTSTRAP + REACT-ICONS IMPORTS
import { Container,Row,Col } from 'react-bootstrap'
import { TbBuildingCastle,TbBed,TbMapSearch, TbPlane, TbGlassFull } from 'react-icons/tb'
import { LanguageData } from '../../interfaces/language'
import { LanguageContext } from '../../context/LanguageContext'
import  { useContext, useEffect, useState } from 'react'
// ROUTING
import { Link } from 'react-router-dom/'
import LandingCard from '../../components/LandingCard/LandingCard'
import { useDispatch } from 'react-redux'
import { changeCategory } from '../../state/reducers/CorvoSlice'
import { changeSubCategory } from '../../state/reducers/ConhecerSlice'
import './Home.css'
import { Bird } from '@phosphor-icons/react'
import { changeBWCategory } from '../../state/reducers/BirdwatchingSlice'

const languageData: LanguageData = require('../../utils/languageData.json');

function Home() : JSX.Element {
    const [language] = useContext(LanguageContext); 
    const homepage = languageData.language[language].page.homepage;
    const [windowWidth,setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () : void =>  setWindowWidth(window.innerWidth);
        setTimeout(handleResize,500);
        window.addEventListener('resize',handleResize); 
        return () => window.removeEventListener('resize',handleResize);
    });
    
    const dispatch = useDispatch();

    return (
        <Container fluid className="landing-img">
            <Row className="filter-drop">

                {windowWidth < 576 ? null : <Col sm={1} md={2} lg={3}/>}
                <Col xs={12} sm={11} md={8} lg={6}>
                    <Link to='/corvo' state={{}} style={{textDecoration: 'none'}}>
                        <h1 id="landing-find">  {homepage.find}  </h1>
                        <h1 id="landing-title"> {homepage.title} </h1>
                    </Link>
                    <hr/>
                    <h2 id="landing-subtitle"> {homepage.subTitle}</h2>
                    <Container fluid>
                        <Row xs={2} sm={3} md={3} lg={8} className="g-4"> 
                        
                            <LandingCard location="/birdwatching" iconNode={<Bird id="landing-card-bw-icon" />}
                                cardTitle={homepage.cards[0].title} onClick={() => dispatch(changeBWCategory("Enquadramento"))}/>

                            <LandingCard location="/conhecer" iconNode={<TbBuildingCastle id="landing-card-icon"/>}
                                cardTitle={homepage.cards[1].title} onClick={() => dispatch(changeSubCategory("Patrimonio"))}/>

                            <LandingCard location="/corvo" iconNode={<TbPlane id="landing-card-icon"/>}
                                cardTitle={homepage.cards[2].title} onClick={() => dispatch(changeCategory("Como Chegar"))}/>

                            <LandingCard location="/conhecer" iconNode={<TbGlassFull id="landing-card-icon"/>}
                                cardTitle={homepage.cards[3].title} onClick={() => dispatch(changeSubCategory("Restaurantes"))}/>

                            <LandingCard location="/conhecer" iconNode={<TbBed id="landing-card-icon"/>}
                                cardTitle={homepage.cards[4].title} onClick={() => dispatch(changeSubCategory("Alojamento"))}/>

                            <LandingCard location="/mapa" iconNode={<TbMapSearch id="landing-card-icon"/>}
                                cardTitle={homepage.cards[5].title} />
                        </Row>
                    </Container>
                    <h4 id="landing-intro"> {homepage.phrase1} </h4>
                    <h4 id="landing-subintro">  {homepage.phrase2} </h4>                
                </Col>
                {windowWidth < 576 ? null : <Col md={2} lg={1}/> }    
          </Row>
        </Container>
  )
}

export default Home