import { createSlice } from "@reduxjs/toolkit";

export const SearchSlice = createSlice({
    name: "search",
    initialState: {
        Page: 1
    },
    reducers: {
        changeSearchPage: (state,action) => {
            state.Page = action.payload
        }
    }
})

export const {changeSearchPage} = SearchSlice.actions;
export default SearchSlice.reducer;