import { createSlice } from "@reduxjs/toolkit";

export const ConhecerSlice = createSlice({
    name:"conhecer",
    initialState: {
        subCategory: "Patrimonio",
        Page: 1
    },
    reducers: {
        changeSubCategory: (state,action) => {
            state.subCategory = action.payload;
        },
        changeConhecerPage: (state,action) => {
            state.Page = action.payload;
        }
    }
})

export const {changeSubCategory,changeConhecerPage} = ConhecerSlice.actions
export default ConhecerSlice.reducer;