// REACT-BOOTSTRAP + REACT-ICONS IMPORTS
import { Offcanvas } from 'react-bootstrap'
import { MdRestaurantMenu } from 'react-icons/md'
import { TbCheese, TbLeaf, TbBed,TbBuildingCastle, TbBrush, TbGlass, TbGlassFull } from 'react-icons/tb'
// REACT
import { useState,Fragment,useContext } from 'react'
// REACT COMPONENTS
import { KnowCard } from '../../components/KnowCard/KnowCard'
// LANGUAGE
import { LanguageData } from '../../interfaces/language'
import { LanguageContext } from '../../context/LanguageContext'
import VerticalRow from '../../components/VerticalRow/VerticalRow'
import CategoriesButton from '../../components/CategoriesButton/CategoriesButton'
// STATE
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../state/store'
import { changeSubCategory } from '../../state/reducers/ConhecerSlice'

const languageData: LanguageData = require('../../utils/languageData.json');

export function Mobile() : JSX.Element {

    const [language] = useContext(LanguageContext); 
    const conhecer = languageData.language[language].page.conhecer;
    const {subCategory} = useSelector((state:StoreState) => state.conhecer);
    const dispatch = useDispatch();

    const [collapseOpen,setCollapseOpen] = useState(false);
    
    return (
        <Fragment>
        <Offcanvas show={collapseOpen} onHide={() => setCollapseOpen(!collapseOpen)} placement="bottom">
            <Offcanvas.Header closeButton> </Offcanvas.Header>
            <Offcanvas.Body>
                <div>
                        <VerticalRow iconState={subCategory === "Patrimonio"} iconNode={<TbBuildingCastle
                                className={subCategory === "Patrimonio" ? "box-explore box-inactive box-icon-patrimonio-in" : "box-explore box-icon-patrimonio"} />}
                                colClass={subCategory === "Patrimonio" ? "box-style box-patrimonio box-unpad" : "box-style box-patrimonio"}
                                colTitle={conhecer.categories[0].title}
                                stateUpdate={() => dispatch(changeSubCategory("Patrimonio"))} />

                        <VerticalRow iconState={subCategory === "Artesanato Local"} iconNode={<TbBrush 
                                className={subCategory === "Artesanato Local" ? "box-explore box-inactive box-icon-gastronomia-in" : "box-explore box-icon-gastronomia"}/>}
                                colClass={subCategory === "Artesanato Local" ? "box-style box-gastronomia box-unpad" : "box-style box-gastronomia"}
                                colTitle={conhecer.categories[1].title}
                                stateUpdate={() => dispatch(changeSubCategory("Artesanato Local"))} />

                        <VerticalRow iconState={subCategory === "Natureza"} iconNode={<TbLeaf
                                className={subCategory === "Natureza" ? "box-explore box-inactive box-icon-natureza-in" : "box-explore box-icon-natureza"}/>}
                                colClass={subCategory === "Natureza" ? "box-style box-natureza box-unpad" : "box-style box-natureza"}
                                colTitle={conhecer.categories[2].title}
                                stateUpdate={() => dispatch(changeSubCategory("Natureza"))} />

                        <VerticalRow iconState={subCategory === "Restaurantes"} iconNode={<TbGlassFull
                                className={subCategory === "Restaurantes" ? "box-explore box-inactive box-icon-comer-in" : "box-explore box-icon-comer"}/>}
                                colClass={subCategory === "Restaurantes" ? "box-style box-comer box-unpad": "box-style box-comer"}
                                colTitle={conhecer.categories[3].title}
                                stateUpdate={() => dispatch(changeSubCategory("Restaurantes"))} />                    

                        <VerticalRow iconState={subCategory === "Alojamento"} iconNode={<TbBed 
                                className={subCategory === "Alojamento" ? "box-explore box-inactive box-icon-dormir-in": "box-explore box-icon-dormir"}/>}
                                colClass={subCategory === "Alojamento" ? "box-style box-dormir box-unpad" : "box-style box-dormir"}
                                colTitle={conhecer.categories[4].title}
                                stateUpdate={() => dispatch(changeSubCategory("Alojamento"))} />  
            </div>
        </Offcanvas.Body>
        </Offcanvas>
        
        <KnowCard />
        <CategoriesButton open={collapseOpen} stateUpdate={() => setCollapseOpen(!collapseOpen)} />
    </Fragment>
    )
}