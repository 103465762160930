import { MapContainer, TileLayer, Marker,Popup } from 'react-leaflet'
import { useContext, useEffect } from 'react'
import { LanguageContext } from '../../context/LanguageContext'
import Flex from '@react-css/flex'
import { Col } from 'react-bootstrap'
import { getBWPoints } from '../../utils/requests'
import { useQuery } from 'react-query'
import { leafletIcon } from '../../interfaces/utils'

function ObservationSpots() : JSX.Element {
    const [language] = useContext(LanguageContext);

    const {data,refetch} = useQuery({queryKey:['bw-points'],queryFn: () => getBWPoints(language)});
    
    useEffect(() => {
        refetch();
    },[language]);

    const mapsText = language === "PT" ? "Abrir no Google Maps" : "Open in Google Maps";
    
    return (
        <Flex flexDirection='column'>
            <Col className='col-11' id="article-text">
                <h4 id="entity-title"> {language === "PT" ? "Locais de Observação" : "Observation Spots"} </h4>
                <MapContainer center={[39.7031, -31.1119]} zoom={12} scrollWheelZoom={true} className="leaflet-container">
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
                    
                        {data?.data.map(({attributes}) => {
                            return (
                                <Marker position={attributes.MapaIntegrado ? attributes.MapaIntegrado.Coordenadas.coordinates : [39.7031, -31.1119]} icon={leafletIcon} riseOnHover={true}>   
                                    <Popup>
                                        <b> {attributes.Titulo}</b>
                                        <p> LAT: {attributes.MapaIntegrado?.Coordenadas.coordinates[0]} </p>
                                        <p> LON: {attributes.MapaIntegrado?.Coordenadas.coordinates[1]} </p>
                                        <a href={`https://www.google.com/maps/place/${attributes.MapaIntegrado?.Coordenadas.coordinates[0]},${attributes.MapaIntegrado?.Coordenadas.coordinates[1]}`} target="_blank"> {mapsText} </a>
                                    </Popup>
                                </Marker>
                            )
                        })}
                   
                </MapContainer>
            </Col>
        </Flex>
    )
}

export default ObservationSpots