import { configureStore } from "@reduxjs/toolkit";
import { BirdwatchingSlice } from "./reducers/BirdwatchingSlice";
import {ConhecerSlice} from "./reducers/ConhecerSlice";
import {CorvoSlice} from "./reducers/CorvoSlice";
import {MenuSlice} from "./reducers/MenuSlice";
import {SearchSlice} from "./reducers/SearchSlice";

export const globalStore = configureStore({
    reducer: {
        birdwatching: BirdwatchingSlice.reducer,
        corvo: CorvoSlice.reducer,
        conhecer: ConhecerSlice.reducer,
        menu: MenuSlice.reducer,
        search: SearchSlice.reducer
    }
})

export default globalStore

export type StoreState = ReturnType<typeof globalStore.getState>;
export type AppDispatch = typeof globalStore.dispatch;