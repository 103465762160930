// REACT-BOOTSTRAP + REACT-ICONS IMPORTS
import { Offcanvas} from 'react-bootstrap'
import { TbConfetti, TbLeaf, TbBuildingCastle,TbMountain,TbPlane} from 'react-icons/tb'
// REACT
import { useState,useContext,useEffect } from 'react'
// OTHER IMPORTS
import Flex from '@react-css/flex'
// LANGUAGE
import { LanguageData } from '../../interfaces/language'
import { LanguageContext } from '../../context/LanguageContext'
import VerticalRow from '../../components/VerticalRow/VerticalRow'
import CategoriesButton from '../../components/CategoriesButton/CategoriesButton'
import ArticleBody from '../../components/ArticleBody/ArticleBody'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../state/store'
import { changeCategory } from '../../state/reducers/CorvoSlice'
import { useQuery } from 'react-query'
import { getArticles } from '../../utils/requests'
import SideCarousel from '../../components/SideCarousel/SideCarousel'


const languageData: LanguageData = require('../../utils/languageData.json');

export function Mobile() : JSX.Element {

    const [language] = useContext(LanguageContext); 
    const corvo = languageData.language[language].page.corvo;
    const {category} = useSelector((state:StoreState) => state.corvo);
    const dispatch = useDispatch();
    const {data,refetch} = useQuery({queryKey:['articles'],queryFn: () => getArticles(language,category)});

    const [show,setShow] = useState(false);
    const [collapseOpen,setCollapseOpen] = useState(false);

    useEffect( () => {
        refetch();
    },[language,category]);

    return (
        <>
        <SideCarousel imgs={data?.at(0)?.attributes.Imagens.data} triggerDisplay={() => setShow(!show)} />

         <Offcanvas show={collapseOpen} onHide={() => setCollapseOpen(!collapseOpen)} placement="bottom">
            <Offcanvas.Header closeButton> </Offcanvas.Header>
            <Offcanvas.Body>            
                <div>
                    <Flex flexDirection='column' className="flex-read" >

                        <VerticalRow iconState={(category === "Geografia")} iconNode={<TbMountain
                            className={(category === "Geografia") ? "box-explore box-inactive box-icon-patrimonio-in" : "box-explore box-icon-patrimonio"} />}
                            colClass={(category === "Geografia") ? "box-style box-patrimonio box-unpad" : "box-style box-patrimonio"}
                            colTitle={corvo.categories[0].title}
                            stateUpdate={() => dispatch(changeCategory("Geografia"))}/>

                        <VerticalRow iconState={(category === "História e Cultura")} iconNode={<TbBuildingCastle
                            className={(category === "História e Cultura") ? "box-explore box-inactive box-icon-gastronomia-in" : "box-explore box-icon-gastronomia"} />}
                            colClass={(category === "História e Cultura") ? "box-style box-gastronomia box-unpad" : "box-style box-gastronomia"}
                            colTitle={corvo.categories[1].title}
                            stateUpdate={() => dispatch(changeCategory("História e Cultura"))}/>

                        <VerticalRow iconState={(category === "Natureza")} iconNode={<TbLeaf
                            className={(category === "Natureza") ? "box-explore box-inactive box-icon-natureza-in" : "box-explore box-icon-natureza"} />}
                            colClass={(category === "Natureza") ? "box-style box-natureza box-unpad" : "box-style box-natureza"}
                            colTitle={corvo.categories[2].title}
                            stateUpdate={() => dispatch(changeCategory("Natureza"))}/>

                        <VerticalRow iconState={(category === "Festividades e Tradições")} iconNode={<TbConfetti
                            className={(category === "Festividades e Tradições") ? "box-explore box-inactive box-icon-eventos-in" : "box-explore box-icon-eventos"} />}
                            colClass={(category === "Festividades e Tradições") ? "box-style box-eventos box-unpad": "box-style box-eventos"}
                            colTitle={corvo.categories[3].title}
                            stateUpdate={() => dispatch(changeCategory("Festividades e Tradições"))}/>

                        <VerticalRow iconState={(category === "Como Chegar")} iconNode={<TbPlane
                            className={(category === "Como Chegar") ? "box-explore box-inactive box-icon-comer-in": "box-explore box-icon-comer"} />}
                            colClass={(category === "Como Chegar") ? "box-style box-comer box-unpad" : "box-style box-comer"}
                            colTitle={corvo.categories[4].title}
                            stateUpdate={() => dispatch(changeCategory("Como Chegar"))}/>    
                    </Flex>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
            <Flex flexDirection='column'>
                <ArticleBody articles={data} type="Corvo"/>
            </Flex>
        <CategoriesButton open={collapseOpen} stateUpdate={() => setCollapseOpen(!collapseOpen)} />
        </>
    )
}