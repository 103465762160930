// REACT-BOOTSTRAP + REACT-ICONS IMPORTS
import { Row,Col,Container } from 'react-bootstrap'
// OTHER IMPORTS
import { getFilteredCards } from '../../utils/requests'
import { useQuery } from 'react-query'
import { useEffect, useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext'
import { useSelector } from 'react-redux'
import { StoreState } from '../../state/store'
import CardsPagination from '../CardsPagination/CardsPagination'
import EntityCard from '../EntityCard/EntityCard'

export function BWCard() : JSX.Element {

    const {BWCategory,Page} = useSelector((state:StoreState) => state.birdwatching);
    const [language] = useContext(LanguageContext);

    const {data,refetch} = useQuery({queryKey:['birdwatching',BWCategory],queryFn: () => getFilteredCards('/birdwatchings',BWCategory,Page,language)})

    useEffect(() => {
      refetch();

      window.scrollTo({
        top:0,
        behavior: "smooth"
      });
      
      
    },[Page,language,BWCategory])
    
    let pageTotal : number = (data?.meta.pagination.pageCount) ?? 1;

    return (
      <Container fluid className='card-container'>
        <Row xs={1} sm={2} md={2} lg={3} xxl={4} className="g-4">
          {data?.data.map((c) =>
            <Col key={c.id}>
               <EntityCard card={c} />
            </Col>
          )}
        </Row>
        <CardsPagination pageTotal={pageTotal} page="birdwatching"/>
      </Container>     
    )         
}