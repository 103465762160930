import { createSlice } from "@reduxjs/toolkit";

export const CorvoSlice = createSlice({
    name: "corvo",
    initialState: {
        category: "Geografia"
    },
    reducers: {
        changeCategory: (state,action) => {
            state.category = action.payload
        }
    }
})

export const {changeCategory} = CorvoSlice.actions;
export default CorvoSlice.reducer;