import { Col} from 'react-bootstrap'
import Flex from '@react-css/flex'
import { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getBWInfo } from '../../utils/requests'
import { LanguageContext } from '../../context/LanguageContext'

function ParagraphLink() : JSX.Element {
    const [language] = useContext(LanguageContext);

    const {data,refetch} = useQuery({queryKey:['birdwatching-art'],queryFn: () => getBWInfo(language)})

    useEffect(() => {
        refetch();
    },[language]);

    return (
        <Flex flexDirection='column'>
            <Col className='col-12' id="article-text">
                <h4 id="entity-title"> 
                    {data?.at(0)?.attributes.Titulo}
                </h4>

                {data?.at(0)?.attributes.Paragrafos.map((p) => 
                    <p key={p.id} id="entity-par">  {p.Paragrafo.split(":")[0]} - <a href={p.Paragrafo.split(":")[1] + ':' + p.Paragrafo.split(":")[2]} target="_blank" > {p.Paragrafo.split(":")[1] + ':' + p.Paragrafo.split(":")[2]} </a> </p>
                )}
                
            </Col>
        </Flex>
    )
}

export default ParagraphLink