import { Nav } from 'react-bootstrap'
import NavBar from 'react-bootstrap/Navbar'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { Link } from 'react-router-dom/'
import { LinkContainer } from 'react-router-bootstrap'
import { FaSearch } from 'react-icons/fa'
import {LanguageContext} from '../../context/LanguageContext'
import { useContext } from 'react'
import { LanguageData } from '../../interfaces/language'
import BWDropdown from '../BWDropdown/BWDropdown'
import { CorvoDropdown } from '../CorvoDropdown/CorvoDropdown'
import { ExploreDropdown } from '../ExploreDropdown/ExploreDropdown'
import { setHighlightPage } from '../../state/reducers/MenuSlice';
import { StoreState } from '../../state/store';
import { useDispatch, useSelector } from 'react-redux'

const languageData: LanguageData = require('../../utils/languageData.json');

export function NavMenus() {

    const [language] = useContext(LanguageContext); 
    const headerLinks = languageData.language[language].header.links;

    const dispatch = useDispatch();
    const {highlightPage} = useSelector((state:StoreState) => state.menu)

    return (
        <NavBar.Collapse id="menus-navbar" >
            
            <Nav className='me-auto'/>
            <BWDropdown />
            <CorvoDropdown />
            <ExploreDropdown />

            <Nav.Item className="navbar-right" id="navbar-separator"> 
                <a target="_blank" href={headerLinks.at(3)?.link} style={{textDecoration:"none",color:"black"}}> 
                    <b> {headerLinks.at(3)?.title} </b> 
                </a>
            </Nav.Item>

            <Nav.Item className="navbar-right" id="navbar-contacts" onClick={() => dispatch(setHighlightPage("Contactos"))}> 
                <Link to='/contactos' style={highlightPage === "Contactos" ? {fontWeight:"bold",color:"gray"} : {}}> {headerLinks.at(4)?.title} </Link> 
            </Nav.Item> 

            <LanguageSelector />
            
            <Nav.Item className="navbar-right" id="navbar-separator" onClick={() => dispatch(setHighlightPage(""))}> 
            <LinkContainer to="/pesquisar">
                <FaSearch/> 
            </LinkContainer>
            </Nav.Item>

        </NavBar.Collapse>
    )
}
