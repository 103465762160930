
import Flex from '@react-css/flex'
import { IconContext } from 'react-icons'
import { VerticalRowProps } from '../../interfaces/utils'

function VerticalRow(props:VerticalRowProps): JSX.Element {
    return (
        <Flex flexDirection='row' id="flex-row" onClick={props.stateUpdate}>
            <IconContext.Provider value={props.iconState ? {size:'20%',color: "white"} : {size:'20%'}}> 
                {props.iconNode}
            </IconContext.Provider>
            
            <Flex flexDirection='row' className={props.colClass} style={{alignItems:"center"}}>
                <Flex flexDirection='column' style={{width:"100%"}}>
                    <h4 > {props.colTitle} </h4>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default VerticalRow