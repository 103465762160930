// REACT-BOOTSTRAP + REACT-ICONS IMPORTS
import { TbFeather,TbMap2, TbInfoCircle, TbBook } from 'react-icons/tb'
// REACT
import { useContext, useEffect, useState } from 'react'
// OTHER IMPORTS
import Flex from '@react-css/flex'
import { Banner } from '../../components/Banners/Banners'
import { BWCard } from '../../components/BWCard/BWCard'
// LANGUAGE
import { LanguageData } from '../../interfaces/language'
import { LanguageContext } from '../../context/LanguageContext'
import VerticalRow from '../../components/VerticalRow/VerticalRow'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../state/store'
import { changeBWCategory } from '../../state/reducers/BirdwatchingSlice'
import ParagraphLink from '../../components/ParagraphLink/ParagraphLink'
import ObservationSpots from '../../components/ObservationSpots/ObservationSpots'
import { useQuery } from 'react-query'
import { getBWData } from '../../utils/requests'
import SideCarousel from '../../components/SideCarousel/SideCarousel'
import ImageModal from '../../components/ImageModal/ImageModal'
import ArticleBody from '../../components/ArticleBody/ArticleBody'

const languageData: LanguageData = require('../../utils/languageData.json');

export function Desktop() : JSX.Element {

    const [language] = useContext(LanguageContext); 
    const birdwatching = languageData.language[language].page.birdwatching;

    const {BWCategory} = useSelector((state:StoreState) => state.birdwatching);
    const dispatch = useDispatch();

    const {data,refetch} = useQuery({queryKey:['bw-data'],queryFn: () => getBWData(language,BWCategory),
          enabled: (BWCategory === "Enquadramento")});

    useEffect(() => {
        refetch();
    },[language,BWCategory]);
    
    const [show,setShow] = useState(false);

    return (
        <>        
            <Banner title="Birdwatching" cssID="categories-banner"/>
            <Flex flexDirection='row'>
                <Flex flexDirection='column' id="flex-style">

                    <VerticalRow iconState={BWCategory === "Enquadramento"} iconNode={<TbBook 
                        className={BWCategory === "Enquadramento" ? "box-explore box-inactive box-icon-comer-in" : "box-explore box-icon-comer"} />}
                        colClass={BWCategory === "Enquadramento" ? "box-style box-comer box-unpad" : "box-style box-comer"}
                        colTitle={birdwatching.categories[0].title}
                        stateUpdate={() =>  dispatch(changeBWCategory("Enquadramento"))} />

                    <VerticalRow iconState={BWCategory === "Especies"} iconNode={<TbFeather  
                        className={BWCategory === "Especies" ? "box-explore box-inactive box-icon-patrimonio-in" : "box-explore box-icon-patrimonio"} />}
                        colClass={BWCategory === "Especies" ? "box-style box-patrimonio box-unpad" : "box-style box-patrimonio"}
                        colTitle={birdwatching.categories[1].title}
                        stateUpdate={() =>  dispatch(changeBWCategory("Especies"))} />

                    <VerticalRow iconState={BWCategory === "Locais de Observacao"} iconNode={<TbMap2
                        className={BWCategory === "Locais de Observacao" ? "box-explore box-inactive box-icon-gastronomia-in" : "box-explore box-icon-gastronomia"} />}
                        colClass={BWCategory === "Locais de Observacao" ? "box-style box-gastronomia box-unpad" : "box-style box-gastronomia"}
                        colTitle={birdwatching.categories[2].title}
                        stateUpdate={()  =>  dispatch(changeBWCategory("Locais de Observacao"))} />

                    <VerticalRow iconState={BWCategory === "Informacoes Uteis"} iconNode={<TbInfoCircle
                        className={BWCategory === "Informacoes Uteis" ? "box-explore box-inactive box-icon-eventos-in" : "box-explore box-icon-eventos"}/>}
                        colClass={BWCategory === "Informacoes Uteis" ? "box-style box-eventos box-unpad" : "box-style box-eventos"}
                        colTitle={birdwatching.categories[3].title}
                        stateUpdate={() => dispatch(changeBWCategory("Informacoes Uteis"))} />
                </Flex>
                
                { BWCategory === "Especies" ? <BWCard /> : null} 
                { BWCategory === "Enquadramento" ? 
                    <>
                        <ArticleBody articles={data} type="Birdwatching" /> 
                        <SideCarousel imgs={data?.at(0)?.attributes.Imagens.data} triggerDisplay = {() => setShow(!show)} />
                        <ImageModal imgs={data?.at(0)?.attributes.Imagens.data} show={show} handleClose={() => setShow(false)} />
                    </>
                : null}
                { BWCategory === "Informacoes Uteis" ? <ParagraphLink /> : null} 
                { BWCategory === "Locais de Observacao" ? <ObservationSpots /> : null} 
                
            </Flex>
        </>
    )    
}