import { Carousel, CarouselItem,Col,Modal,Card,Accordion,Row} from 'react-bootstrap'
import Flex from '@react-css/flex';
import { useContext, useState } from 'react';
import { EmbededMap } from '../../components/EmbededMap/EmbededMap';
import { IconContext } from 'react-icons'
import { BuildingCastle, BuildingCommunity, BuildingHospital, Firetruck, HomeSearch, PlaneDeparture, School, SquareRoundedLetterP } from 'tabler-icons-react'
import IconContacts from '../../components/IconContacts/IconContacts';

// IMAGE IMPORTS
import cmcorvo from '../../assets/img/cmcorvo.jpg'
import nova from '../../assets/img/2.jpg'

import { LanguageData } from '../../interfaces/language'
import { LanguageContext } from '../../context/LanguageContext';
import { TbMedicineSyrup } from 'react-icons/tb';

const languageData: LanguageData = require('../../utils/languageData.json');

export function Mobile() : JSX.Element {
    const [show, setShow] = useState(false);
    const [slideIndex,setSlideIndex] = useState(0);
    const handleClose = () : void => setShow(false);
    const [language] = useContext(LanguageContext);
    const contactos = languageData.language[language].page.contactos.categories;

    return (
        <>
            <Carousel id="side-carousel">
                <CarouselItem>
                    <a onClick={(e) => {
                        e.preventDefault();
                        setShow(!show)}}>
                        <img src={nova} alt="123" id="entity-img"></img> 
                    </a>
                </CarouselItem>
                
                <CarouselItem>
                    <a onClick={(e) => {
                        e.preventDefault();
                        setShow(!show)}}>
                        <img src={cmcorvo} alt="123" id="entity-img"></img> 
                    </a>
                </CarouselItem>
            </Carousel>

            <Flex flexDirection='row'>
                <Col>
                    <Flex flexDirection='column'>
                    <Row>
                        <Col className='col-12'>
                            <Accordion>
                                <Accordion.Item eventKey='0'>
                                    <Accordion.Header> 
                                        <IconContext.Provider value={{size:'2%'}}> 
                                            <BuildingCastle style={{marginRight:".5rem"}}/> {contactos[0].title}
                                         </IconContext.Provider>
                                         </Accordion.Header>
                                    <Accordion.Body>
                                        <Card>
                                            <Card.Header id="contacts-header-title"> {contactos[0].entities[0]} </Card.Header> {/* MUNICIPIO */}
                                            <Card.Text> <IconContacts location={`Rua Jogo da Bola, 9980-025 Vila Do Corvo`} telephone='292 590 200' email='geral@cm-corvo.pt' isContactsPage={true} /></Card.Text>
                                        </Card>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='1'>
                                    <Accordion.Header> 
                                        <IconContext.Provider value={{size:'2%'}}> 
                                            <BuildingHospital style={{marginRight:".5rem"}}/> {contactos[1].title}
                                         </IconContext.Provider>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Card>
                                            <Card.Header id="contacts-header-title">{contactos[1].entities[0]}</Card.Header> {/* CENTRO DE SAUDE */}
                                            <Card.Text><IconContacts location={`Avenida Nova, s/n, 9980-000 Vila Do Corvo`} telephone='292 596 153' email='sres-usic@azores.gov.pt' isContactsPage={true} /></Card.Text>
                                        </Card>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='2'>
                                    <Accordion.Header> 
                                        <IconContext.Provider value={{size:'2%'}}> 
                                            <Firetruck style={{marginRight:".5rem"}}/> {contactos[2].title}
                                         </IconContext.Provider>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Card>
                                            <Card.Header id="contacts-header-title"> {contactos[2].entities[0]} </Card.Header> {/* BOMBEIROS */}
                                            <Card.Text> <IconContacts location={`Caminho dos Moinhos, s/n, 9980-032 Vila Do Corvo`} telephone='292 596 229' isContactsPage={true} /> </Card.Text>
                                        </Card>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='3'>
                                    <Accordion.Header> 
                                        <IconContext.Provider value={{size:'1.3rem'}}> 
                                            <TbMedicineSyrup style={{marginRight:".5rem"}}/> {contactos[3].title}
                                         </IconContext.Provider>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Card>
                                            <Card.Header id="contacts-header-title">{contactos[3].entities[0]}  </Card.Header> {/* FARMÁCIA */}
                                            <Card.Text><IconContacts location={`Rua Jogo da Bola, 9980-025 Vila Do Corvo`} telephone='292 590 085' isContactsPage={true} /> </Card.Text>
                                        </Card>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey='4'>
                                    <Accordion.Header> 
                                        <IconContext.Provider value={{size:'2%'}}> 
                                            <HomeSearch style={{marginRight:".5rem"}}/> {contactos[4].title}
                                         </IconContext.Provider>
                                    </Accordion.Header>
                                    <Accordion.Body>        
                                        <Card>
                                            <Card.Header id="contacts-header-title">{contactos[4].entities[0]} </Card.Header> {/* Posto de Turismo */}
                                            <Card.Text><IconContacts location={`Caminho dos Moinhos, s/n, 9980-032 Vila Do Corvo`} telephone='292 596 227' isContactsPage={true} /> </Card.Text>
                                        </Card>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey='5'>
                                    <Accordion.Header> 
                                        <IconContext.Provider value={{size:'2%'}}> 
                                            <PlaneDeparture style={{marginRight:".5rem"}}/> {contactos[5].title}
                                        </IconContext.Provider>
                                    </Accordion.Header>
                                    <Accordion.Body>         
                                        <Card>
                                            <Card.Header id="contacts-header-title"> {contactos[5].entities[0]} </Card.Header> {/* Posto de Turismo */}
                                            <Card.Text><IconContacts location={`Caminho dos Moinhos, s/n, 9980-032 Vila Do Corvo`} telephone='292 596 227' isContactsPage={true} /> </Card.Text>
                                        </Card>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey='6'>
                                    <Accordion.Header> 
                                        <IconContext.Provider value={{size:'2%'}}> 
                                            <BuildingCommunity style={{marginRight:".5rem"}}/> {contactos[6].title}
                                         </IconContext.Provider>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row xs={1} md={2} className="g-4">
                                            <Col> 
                                                <Card>         
                                                    <Card.Header id="contacts-header-title"> {contactos[6].entities[0]} </Card.Header> {/* CULTURA */}
                                                    <Card.Text> <IconContacts location={`Rua da Matriz, s/n, 9980-000 Vila Do Corvo`} telephone='292 590 085' isContactsPage={true} /> </Card.Text>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>          
                                                    <Card.Header id="contacts-header-title"> {contactos[6].entities[1]} </Card.Header> {/* CULTURA */}
                                                    <Card.Text> <IconContacts location={`Canada do Graciosa, s/n, 9980-032 Vila Do Corvo`} telephone='292 596 051' isContactsPage={true} /> </Card.Text>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>          
                                                    <Card.Header id="contacts-header-title"> {contactos[6].entities[2]} </Card.Header> {/* CULTURA */}
                                                    <Card.Text> <IconContacts location={`Rua das Pedras, s/n, 9980-025 Vila Do Corvo`} telephone='292 596 063' isContactsPage={true} /> </Card.Text>
                                                </Card>
                                            </Col> 
                                            <Col>
                                                <Card>          
                                                    <Card.Header id="contacts-header-title"> {contactos[6].entities[3]} </Card.Header> {/* CULTURA */}
                                                    <Card.Text> <IconContacts location={`Largo do Maranhão, s/n, 9980-050 Vila Do Corvo`} telephone='292 241 450' email='parque.natural@azores.gov.pt' isContactsPage={true} /> </Card.Text>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='7'>
                                <Accordion.Header> 
                                        <IconContext.Provider value={{size:'2%'}}> 
                                            <School style={{marginRight:".5rem"}}/> {contactos[7].title}
                                        </IconContext.Provider>
                                        
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row xs={1} md={2} className="g-4">
                                            <Col>
                                                <Card>         
                                                    <Card.Header id="contacts-header-title"> {contactos[7].entities[0]} </Card.Header> {/* ESTABLECIMENTO ESCOLAR */}
                                                    <Card.Text> <IconContacts location={`Rua do Jogo da Bola, s/n, 9980-032 Vila Do Corvo`} telephone='292 596 288' isContactsPage={true} /> </Card.Text>
                                                </Card>
                                            </Col>

                                            <Col> 
                                                <Card>         
                                                    <Card.Header id="contacts-header-title">{contactos[7].entities[1]}</Card.Header> {/* ESTABLECIMENTO ESCOLAR */}
                                                    <Card.Text> <IconContacts location={`Rua do Jogo da Bola, s/n, 9980-032 Vila Do Corvo`} isContactsPage={true} /> </Card.Text>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='8'>
                                    <Accordion.Header> 
                                        
                                        <IconContext.Provider value={{size:'2%'}}> 
                                            <SquareRoundedLetterP style={{marginRight:".5rem"}}/> {contactos[8].title}
                                        </IconContext.Provider>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row xs={1} md={2} className="g-4">
                                            <Col> 
                                                <Card>         
                                                    <Card.Header id="contacts-header-title"> {contactos[8].entities[0]}</Card.Header> {/* POLICIA */}
                                                    <Card.Text><IconContacts location={`Rua da Cruz, s/n, 9980-000 Vila Do Corvo`} telephone='292 596 261' isContactsPage={true} /> </Card.Text>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>          
                                                    <Card.Header id="contacts-header-title"> {contactos[8].entities[1]} </Card.Header> {/* POLICIA */}
                                                    <Card.Text> <IconContacts location={`Avenida Nova, s/n, 9980-039 Vila Do Corvo`} telephone='292 596 146' isContactsPage={true} /> </Card.Text>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                          
                    <EmbededMap coordinates={[39.7031,-31.1119]} page="Contactos" />
                </Flex>
                </Col>
          </Flex>

        <Modal show={show} onHide={handleClose} fullscreen={true}>
          <Modal.Header closeButton />
          <Modal.Body>
            <Carousel indicators={false} onSelect={(activeIndex) => setSlideIndex(activeIndex)} activeIndex={slideIndex} slide={true}>
              <CarouselItem>
                <img src={nova} alt="123" id="entity-modal-img"></img> 
              </CarouselItem>
              
              <CarouselItem>
                <img src={cmcorvo} alt="123" id="entity-modal-img"></img> 
              </CarouselItem>
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <img src={nova} alt="123" onClick={() => setSlideIndex(0)} key={0}
                height="80" width="80" loading="lazy"></img>
            <img src={cmcorvo} alt="123" onClick={() => setSlideIndex(1)} key={1}
                height="80" width="80" loading="lazy"></img> 
          </Modal.Footer>
        </Modal> 

        </>
    )
}