import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CustomDropDownItemProps } from "../../interfaces/utils";

function CustomDropDownItem(props:CustomDropDownItemProps) : JSX.Element {
  return (
    <NavDropdown.Item id="navbar-separator" style={{paddingTop:"6px",paddingBottom:"6px"}}> 
      <Link to={props.location} onClick={props.onClick}> {props.title} </Link>
    </NavDropdown.Item>
  )
}

export default CustomDropDownItem