import { IconContext } from 'react-icons'
import { TbPhone,TbMail,TbMapPin,TbWorld} from 'react-icons/tb'
import { IconContactsProps } from '../../interfaces/utils'

function IconContacts({location,telephone,email,website,isContactsPage}:IconContactsProps) : JSX.Element {
    return (
        <div className={isContactsPage ? "contacts-info" : "item-infos"}>

            {location ?
                <>
                    <IconContext.Provider value={{}}>
                        <TbMapPin id="pin"/>
                        {location}
                    </IconContext.Provider>
                    <br />
                </>
            : null }

            {website?
            <>
                <IconContext.Provider value={{}}>
                    <TbWorld id="fb"/>
                        <a href={website} target="_blank"> {website} </a>
                </IconContext.Provider>
                <br />
            </>
            :null}

            { telephone ?
            <>
                <IconContext.Provider value={{}}>
                    <TbPhone id="phone"/> 
                    {telephone}
                </IconContext.Provider>
                <br />
            </>
            : null }

            { email ? 
            <>
                <IconContext.Provider value={{}}>
                    <TbMail id="mail"/>
                    {email}
                </IconContext.Provider>
                <br />
            </>
            : null }            
            
        </div>
    )
}

export default IconContacts
