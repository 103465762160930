import { LatLngTuple } from "leaflet"
import { ReactElement, ReactNode } from "react"
import { ArticleJSON, CardJSON, MultipleEntityData, ThumbnailJSON } from "./api_responses"
import L from 'leaflet'
import iconMarker from 'leaflet/dist/images/marker-icon.png'
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

export interface BannerProps {
    title : string,
    cssID: string
}

export interface BCBannerProps {
    category: string,
    subCategory:string
}

export interface EntityProps extends BCBannerProps {
    entityID: number
}

export interface VerticalRowProps {
    iconState:boolean,
    iconNode: ReactNode,
    colClass:string,
    colTitle:string,
    stateUpdate: () => any,
}

export interface SearchResultsProps {
    headerTitle:string,
    query:string
}

export interface LandingCardProps {
    location:string,
    iconNode:ReactNode,
    cardTitle:string,
    onClick? : React.MouseEventHandler<HTMLButtonElement>;
}

export interface ImageModalProps {
    imgs: ThumbnailJSON[] | undefined,
    show:boolean,
    handleClose: () => void
}

export interface SideCarouselProps {
    imgs: ThumbnailJSON[] | undefined,
    triggerDisplay: () => void
}

export interface ArticleBodyProps {
    articles : ArticleJSON[] | undefined,
    type: string
}

export interface RelatedCardProps {
    articles: MultipleEntityData | undefined
}

export interface EmbededMapProps {
    type?: string,
    coordinates: LatLngTuple,
    page?:string
}

export interface CategoriesButtonProps {
    open:boolean,
    stateUpdate: () => void
}

export interface IconContactsProps {
    location?:string,
    telephone?:string,
    email?:string,
    website?:string,
    isContactsPage?:boolean
}

export interface PaginatedResultsProps {
    pageItems: ReactElement[]
}

export const leafletIcon = L.icon({
    iconRetinaUrl:iconRetina, 
    iconUrl: iconMarker, 
    shadowUrl: iconShadow 
})

export interface PaginationProps {
    pageTotal: number,
    page?: string
}

export interface CustomDropDownItemProps {
    onClick: () => void,
    location: string,
    title: string | undefined
}

export interface EntityCardProps {
    card:CardJSON
}