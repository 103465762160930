import { Col,Card } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { LinkContainer } from 'react-router-bootstrap'
import { LandingCardProps } from '../../interfaces/utils'

function LandingCard({location,iconNode,cardTitle,onClick}:LandingCardProps) : JSX.Element {
    return (
        <Col onClick={onClick}>
            <LinkContainer to={location}>                    
                <Card className="landing-card" > 
                    <Card.Title className="landing-card-border"> 
                        <IconContext.Provider value={{size:"70%"}}>
                            {iconNode}
                        </IconContext.Provider>
                        <br/> 
                        {cardTitle}
                    </Card.Title>
                </Card>
            </LinkContainer>
        </Col>
    )
}

export default LandingCard