// REACT-BOOTSTRAP + REACT-ICONS IMPORTS
import { Row,Col,Container } from 'react-bootstrap'
// OTHER IMPORTS
import { getFilteredCards } from '../../utils/requests'
import { useQuery } from 'react-query'
import { useEffect, useContext, useRef } from 'react'
import { LanguageContext } from '../../context/LanguageContext'
import { useSelector } from 'react-redux'
import { StoreState } from '../../state/store'
import CardsPagination from '../CardsPagination/CardsPagination'
import LinkedEntityCard from '../LinkedEntityCard/LinkedEntityCard'

export function KnowCard() : JSX.Element {

    const {subCategory,Page} = useSelector((state:StoreState) => state.conhecer);
    
    const [language] = useContext(LanguageContext);
    const previousSubCategory = useRef(subCategory);

    const {data,refetch} = useQuery({queryKey:['conhecer',subCategory],queryFn: () => getFilteredCards('/conhecers',subCategory,Page,language)});

    useEffect(() => {
      refetch();

      window.scrollTo({
        top:0,
        behavior: "smooth"
      });
      
      if (previousSubCategory.current !== subCategory) {
        previousSubCategory.current = subCategory
      }

    },[Page,language,subCategory]);

    let pageTotal: number = (data?.meta.pagination.pageCount) ?? 1;

    return (
        <Container fluid className="card-container">
          <Row xs={1} sm={2} md={2} lg={3} xxl={4} className="g-4">
            {data?.data.map((c) => 
              <Col key={c.id}>
                  <LinkedEntityCard card={c} />
              </Col>
            )}
          </Row>
          {pageTotal > 1 ? <CardsPagination pageTotal={pageTotal} page="conhecer" /> : null}
        </Container>                                              
    )
}