import { useState, createContext, ReactNode } from 'react';
// Resources
import { LanguageData } from '../interfaces/language';
// JSON
const languageData: LanguageData = require('../utils/languageData.json');
const languages = { ...languageData.language };

const getLanguageFromCache = (): string | null => localStorage.getItem('language');

const getLanguageFromBrowser = (): string | null => navigator.language;

const getLanguage = () => {
  const language = getLanguageFromCache() ?? getLanguageFromBrowser();
  return (language?.includes('pt') || language?.includes('PT')) ? languages.PT.display : languages.EN.display;
}

// Create Context Object
export const LanguageContext = createContext<[string, (language: string) => void]>([languages.PT.display, () => {}]);

interface Props {
  children: ReactNode;
}

// Create a provider for components to consume and subscribe to changes
export default function LanguageContextProvider({children}: Props) {
  // State
  const [language, setLanguage] = useState<string>(getLanguage());

  const handleSetLanguage = (language: string): void => {
    localStorage.setItem('language', language);
    setLanguage(language);
  };

  return (
    <LanguageContext.Provider value={[language, handleSetLanguage]}>
      {children}
    </LanguageContext.Provider>
  );
}
