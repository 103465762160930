import { useContext, useState } from 'react'
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../context/LanguageContext'
import { LanguageData } from '../../interfaces/language';
import { changeSubCategory } from '../../state/reducers/ConhecerSlice';
import CustomDropDownItem from '../CustomDropdownItem/CustomDropDownItem';
import { setHighlightPage } from '../../state/reducers/MenuSlice';
import { StoreState } from '../../state/store';

const languageData: LanguageData = require('../../utils/languageData.json');

export const ExploreDropdown = () => {
    const [language] = useContext(LanguageContext); 
    const headerLinks = languageData.language[language].header.links;
    const conhecer_language = headerLinks.at(2);

    const dispatch = useDispatch();
    const {highlightPage} = useSelector((state:StoreState) => state.menu)

  return (
    <NavDropdown title={conhecer_language?.title} id="navbar-separator" className="navbar-right" style={highlightPage==="Conhecer" ? {fontWeight:"bold",color:"gray"} : {}}>

        <CustomDropDownItem title={conhecer_language?.submenu?.at(0)?.title} location="/conhecer" 
        onClick={() => { 
        dispatch(setHighlightPage("Conhecer"));;
        dispatch(changeSubCategory("Patrimonio"))}} />

        <CustomDropDownItem title={conhecer_language?.submenu?.at(1)?.title} location="/conhecer" 
        onClick={() => { 
        dispatch(setHighlightPage("Conhecer"));;
        dispatch(changeSubCategory("Artesanato Local"))}} />

        <CustomDropDownItem title={conhecer_language?.submenu?.at(2)?.title} location="/conhecer" 
        onClick={() => { 
        dispatch(setHighlightPage("Conhecer"));;
        dispatch(changeSubCategory("Natureza"))}} />

        <CustomDropDownItem title={conhecer_language?.submenu?.at(3)?.title} location="/conhecer" 
        onClick={() => { 
        dispatch(setHighlightPage("Conhecer"));;
        dispatch(changeSubCategory("Restaurantes"))}} />


        <CustomDropDownItem title={conhecer_language?.submenu?.at(4)?.title} location="/conhecer" 
        onClick={() => { 
        dispatch(setHighlightPage("Conhecer"));;
        dispatch(changeSubCategory("Alojamento"))}} />

    </NavDropdown>
  )
}
