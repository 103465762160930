import axios from "axios"
import { API } from "./api"
import { ArticleData, ArticleJSON, CardData, EntityData, EntityJSON, MultipleEntityData } from "../interfaces/api_responses";

const {ENDPOINT,POPULATE,CARDFIELDS} = API;

// Retorna toda a informação para os cards!!
export async function getFilteredCards(path:string,category:string,page:number,language:string) : Promise<CardData> {
    let targetURL:string = `${ENDPOINT}${path}?populate[Entidade][fields][0]=id&populate[Thumbnail][fields][0]=formats&filters[Language][$eq]=${language}&filters[Categoria][$eq]=${category}&pagination[pageSize]=8&pagination[page]=${page}${CARDFIELDS}`;
    return axios.get<CardData>(targetURL).then(({data}) => data);
}

// Retorna informação para a entidade!!
export async function getEntity(entityID:string | number) : Promise<EntityJSON> {
    return axios.get<EntityData>(ENDPOINT + "/conhecers/" +  entityID + POPULATE)
            .then(({data}) => data.data);
}

export async function getEntities(text:string,language:string,page:number) : Promise<MultipleEntityData> {
    return axios.get<MultipleEntityData>(ENDPOINT + "/conhecers" + POPULATE + `&filters[Paragrafos][Paragrafo][$contains]=${text}&filters[Language][$eq]=${language}&pagination[pageSize]=5&pagination[page]=${page}`)
    .then(({data}) => data);
}

export async function getArticles(language:string,category:string): Promise<ArticleJSON[]> {
    let targetURL:string = `${ENDPOINT}/articles?populate[Paragrafos][populate][0]=Paragrafo&populate[Imagens][populate][0]=Imagem&populate[Entidades][populate][0]=Thumbnail&filters[Language][$eq]=${language}&filters[Categoria][$eq]=${category}`;
    return axios.get<ArticleData>(targetURL).then(({data}) => data.data);
}

export async function getMapPoints(language:string) : Promise<MultipleEntityData> {
    return axios.get<MultipleEntityData>(ENDPOINT + "/conhecers" + POPULATE + `&filters[Language][$eq]=${language}&pagination[pageSize]=50`)
    .then(({data}) => data);
}

export async function getBWData(language:string,category:string) : Promise<ArticleJSON[]> {
    return axios.get<ArticleData>(ENDPOINT + `/birdwatchings?populate=%2A&filters[Categoria][$eq]=${category}&filters[Language][$eq]=${language}`).then(({data}) => data.data);
}

export async function getBWArticles(language:string) : Promise<ArticleJSON[]> {
    return axios.get<ArticleData>(ENDPOINT + `/birdwatchings?populate=%2A&filters[Categoria][$eq]=Enquadramento&filters[Language][$eq]=${language}`).then(({data}) => data.data);
}

export async function getBWInfo(language:string) : Promise<ArticleJSON[]> {
    return axios.get<ArticleData>(ENDPOINT + `/birdwatchings?populate=%2A&filters[Categoria][$eq]=Informacoes Uteis&filters[Language][$eq]=${language}`).then(({data}) => data.data);
}

export async function getBWPoints(language:string) : Promise<ArticleData> {
    return axios.get<ArticleData>(ENDPOINT + `/birdwatchings?populate=%2A&filters[Categoria][$eq]=Locais de Observacao&filters[Language][$eq]=${language}`).then(({data}) => data);
}