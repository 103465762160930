import { Carousel, CarouselItem } from 'react-bootstrap'
import { SideCarouselProps } from '../../interfaces/utils';

function SideCarousel(props:SideCarouselProps) : JSX.Element {
    return (
        <Carousel id="side-carousel" fade>
            {props.imgs?.map((i,index) =>
                <CarouselItem key={index}>
                    <a onClick={(e) => { 
                        e.preventDefault();
                        props.triggerDisplay()}}>
                        <img src={i.attributes.url} alt={i.attributes.caption} id="entity-img" loading="lazy"></img> 
                    </a>  
                </CarouselItem> 
            )} 
        </Carousel>  
    )
}

export default SideCarousel