import { useContext, useState } from 'react';
// Context
import { LanguageContext } from '../../context/LanguageContext';
// Flex + React-Bootstrap
import Flex from '@react-css/flex'
import { Nav,Popover,OverlayTrigger } from 'react-bootstrap'

export default function LanguageSelector() {
  // Context
  const [language, setLanguage] = useContext(LanguageContext);
  const [showLanguages,setShowLanguages] = useState(false);

  const updateLanguage = () : void =>  {
    setLanguage( (language==="PT") ? "EN" : "PT" );
    setShowLanguages(!showLanguages);
  }

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={
        <Popover id="popover-basic">
          <Popover.Body>
            <Flex flexDirection='row' onClick={updateLanguage}> {(language==="PT") ? <b>Português</b> : "Português"} </Flex>
            <Flex flexDirection='row' onClick={updateLanguage}> {(language==="EN") ? <b>English</b> : "English"} </Flex>
          </Popover.Body>
      </Popover>} show={showLanguages} onToggle={() => setShowLanguages(!showLanguages)}>
      <Nav.Item className="navbar-right" id="navbar-separator"> {language} </Nav.Item>
    </OverlayTrigger> 
  );
}