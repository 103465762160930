import NavBar from 'react-bootstrap/Navbar'
// REACT IMPORTS
import { Link } from 'react-router-dom/'
// LANGUAGE 
import { useDispatch } from 'react-redux'
import { NavMenus } from '../NavMenus/NavMenus'
import { setHighlightPage } from '../../state/reducers/MenuSlice'

function Header() : JSX.Element {

  const dispatch = useDispatch();

  return (
    <NavBar bg="light" expand="lg" id="navbar-style">
      
      <NavBar.Brand id="navbar-brand" onClick={() => dispatch(setHighlightPage(""))}>
        <Link to='/' >
          <img id ="landing-logo" alt = "" src={require("../../assets/img/brasao-corvo.png")} width="80" height="80" className='d-inline-block align-top'/>
        </Link>
      </NavBar.Brand>

      <div className='portal-title'>
          <h4> <b> GeoCorvo </b> </h4> 
          <h6> GeoPortal Municipal do Corvo</h6>
      </div>

      <NavBar.Toggle aria-controls="menus-navbar"/>
      <NavMenus />
    </NavBar>
  );
}

export default Header