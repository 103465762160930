import { useState } from 'react'
import { Carousel, CarouselItem,Modal} from 'react-bootstrap'
import { ImageModalProps } from '../../interfaces/utils'

function ImageModal(props:ImageModalProps) : JSX.Element {

  const [slideIndex,setSlideIndex] = useState(0);

    return (
        <Modal show={props.show} onHide={props.handleClose} fullscreen={true} >
          <Modal.Header closeButton />
          <Modal.Body>
            <Carousel indicators={false} onSelect={(activeIndex) => setSlideIndex(activeIndex)} activeIndex={slideIndex} slide={true}>
              {props.imgs?.map((i,index) =>
                <CarouselItem key={index}>
                  <img src={i.attributes.url} alt={i.attributes.caption} id="entity-modal-img" loading="lazy"/> 
                </CarouselItem>
              )}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            {props.imgs?.map((i,index) =>
                <img src={i.attributes.url} alt={i.attributes.caption} 
                onClick={() => setSlideIndex(index)} key={index}
                height="80" width="80" loading="lazy"/> 
            )}
          </Modal.Footer>
        </Modal> 
    )
}

export default ImageModal