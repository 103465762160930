import { ContactsPoints } from '../../utils/points'
import { Marker, Popup} from 'react-leaflet'
import { leafletIcon } from '../../interfaces/utils'

function ContactsMarkers() : JSX.Element {
    const points = ContactsPoints;
    
    return (
        <>
            {points.map(({name,coordinates}) => {
                return (
                <Marker position={[coordinates[0],coordinates[1]]} icon={leafletIcon} riseOnHover={true}>   
                    <Popup>
                        <b> {name}</b>
                        <p> LAT: {coordinates[0]} </p>
                        <p> LON: {coordinates[1]} </p>
                    </Popup>
                </Marker>
                )
            })}
        </>
    )
}

export default ContactsMarkers