// LEAFLET IMPORTS
import { MapContainer, TileLayer, Marker,Popup, LayersControl } from 'react-leaflet'
import { useQuery } from 'react-query'
import { getMapPoints } from '../../utils/requests'
import { Link } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { LanguageContext } from '../../context/LanguageContext'
import { leafletIcon } from '../../interfaces/utils'

function MapaInterativo() : JSX.Element {

    const [language] = useContext(LanguageContext);

    const {data,refetch} = useQuery({queryKey:['map-points'],queryFn: () => getMapPoints(language)});  
    
    useEffect(() => {
      refetch();
    },[language]);
    
    return (
        <MapContainer center={[39.7031, -31.1119]} zoom={12} scrollWheelZoom={true} className="leaflet-container" style={{height: "90vh",width:"100vw",marginTop:".2rem"}}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
          <LayersControl position="topright">  
              {data?.data.map(({id,attributes}) =>
               <LayersControl.Overlay checked name={attributes.Titulo}>
                  <Marker position={attributes.MapaIntegrado ? attributes.MapaIntegrado.Coordenadas.coordinates : [39.7031, -31.1119]} icon={leafletIcon} riseOnHover={true}>   
                      <Popup>
                        <Link to={`/entidade/${id}`} target="_blank" rel="noopener noreferrer">  {attributes.Titulo} </Link>
                      </Popup>
                  </Marker>
              </LayersControl.Overlay>
              )}
          </LayersControl>
      </MapContainer>
    )
}

export default MapaInterativo