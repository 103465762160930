import { Row,Col,Pagination } from "react-bootstrap"
import { PaginatedResultsProps } from "../../interfaces/utils";

export function PaginatedResults({pageItems}:PaginatedResultsProps) : JSX.Element {
    return (
        <Row style={{marginTop: "2rem"}}>
            <Col className='col-2'>
                <Pagination> {pageItems} </Pagination>
            </Col>
        </Row>
    )
}