import { useContext } from 'react'
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../context/LanguageContext'
import { LanguageData } from '../../interfaces/language';
import { changeCategory } from '../../state/reducers/CorvoSlice'
import { setHighlightPage } from '../../state/reducers/MenuSlice';
import { StoreState } from '../../state/store';
import CustomDropDownItem from '../CustomDropdownItem/CustomDropDownItem';

const languageData: LanguageData = require('../../utils/languageData.json');

export const CorvoDropdown = () => {
    const [language] = useContext(LanguageContext); 
    const dispatch = useDispatch();

    const {highlightPage} = useSelector((state:StoreState) => state.menu)

    const headerLinks = languageData.language[language].header.links;
    const corvo_language = headerLinks.at(1);
    
    return (
        <NavDropdown title={corvo_language?.title} id="navbar-separator" className="navbar-right" style={highlightPage === "Corvo" ? {fontWeight:"bold",color:"gray"} : {}}>
            
            <CustomDropDownItem title={corvo_language?.submenu?.at(0)?.title} location="/corvo" 
            onClick={() => { 
                dispatch(setHighlightPage("Corvo"));
                dispatch(changeCategory("Geografia"))}} />

            <CustomDropDownItem title={corvo_language?.submenu?.at(1)?.title} location="/corvo" 
            onClick={() => { 
                dispatch(setHighlightPage("Corvo"));
                dispatch(changeCategory("História e Cultura"))}} />

            <CustomDropDownItem title={corvo_language?.submenu?.at(2)?.title} location="/corvo" 
            onClick={() => { 
                dispatch(setHighlightPage("Corvo"));
                dispatch(changeCategory("Natureza"))}} />

            <CustomDropDownItem title={corvo_language?.submenu?.at(3)?.title} location="/corvo" 
            onClick={() => { 
                dispatch(setHighlightPage("Corvo"));
                dispatch(changeCategory("Festividades e Tradições"))}} />

            <CustomDropDownItem title={corvo_language?.submenu?.at(4)?.title} location="/corvo" 
            onClick={() => { 
                dispatch(setHighlightPage("Corvo"));
                dispatch(changeCategory("Como Chegar"))}} />
    
        </NavDropdown>
  )
}
