import { useContext } from 'react'
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../context/LanguageContext'
import { LanguageData } from '../../interfaces/language';
import { changeBWCategory } from '../../state/reducers/BirdwatchingSlice'
import CustomDropDownItem from '../CustomDropdownItem/CustomDropDownItem';
import { setHighlightPage } from '../../state/reducers/MenuSlice';
import { StoreState } from '../../state/store';

const languageData: LanguageData = require('../../utils/languageData.json');

function BWDropdown() {
    const [language] = useContext(LanguageContext);
    const headerLinks = languageData.language[language].header.links;
    const birdwatching_language = headerLinks.at(0);

    const dispatch = useDispatch();
    const {highlightPage} = useSelector((state:StoreState) => state.menu)

  return (
    <NavDropdown title={birdwatching_language?.title} id="navbar-separator" className="navbar-right" style={highlightPage === "Birdwatching" ? {fontWeight:"bold",color:"gray"} : {}}>
          
        <CustomDropDownItem title={birdwatching_language?.submenu?.at(0)?.title} location="/birdwatching" 
        onClick={() => { 
        dispatch(setHighlightPage("Birdwatching"));;
        dispatch(changeBWCategory("Enquadramento"))}} />

        <CustomDropDownItem title={birdwatching_language?.submenu?.at(1)?.title} location="/birdwatching" 
        onClick={() => { 
        dispatch(setHighlightPage("Birdwatching"));;
        dispatch(changeBWCategory("Especies"))}} />

        <CustomDropDownItem title={birdwatching_language?.submenu?.at(2)?.title} location="/birdwatching" 
        onClick={() => { 
        dispatch(setHighlightPage("Birdwatching"));;
        dispatch(changeBWCategory("Locais de Observacao"))}} />

        <CustomDropDownItem title={birdwatching_language?.submenu?.at(3)?.title} location="/birdwatching" 
        onClick={() => { 
        dispatch(setHighlightPage("Birdwatching"));;
        dispatch(changeBWCategory("Informacoes Uteis"))}} />

    </NavDropdown>
  )
}

export default BWDropdown